import axios from "axios";
import phoneLib from "google-libphonenumber";

export const environment = process.env.NEXT_PUBLIC_NEXT_ENV;
function getURL ( environment )
{
    switch ( environment )
    {
        case "local":
            return process.env.NEXT_PUBLIC_LOCAL_URL;
        case "dev":
            return process.env.NEXT_PUBLIC_DEV_URL;
        case "stage":
            return process.env.NEXT_PUBLIC_STAGE_URL;
        case "prod":
            return process.env.NEXT_PUBLIC_PROD_URL;
        default:
            return process.env.NEXT_PUBLIC_LOCAL_URL;
    }
}
function getPublicS3URL ( environment )
{
    switch ( environment )
    {
        case "local":
            return process.env.NEXT_PUBLIC_S3_URL_DEV;
        case "dev":
            return process.env.NEXT_PUBLIC_S3_URL_DEV;
        case "stage":
            return process.env.NEXT_PUBLIC_S3_URL_DEV;
        case "prod":
            return process.env.NEXT_PUBLIC_S3_URL_PROD;
        default:
            return process.env.NEXT_PUBLIC_S3_URL_DEV;
    }
}
// Server URL depending on environment
export const serverAPI = getURL( environment );
export const publicS3Bucket = getPublicS3URL( environment );
export const getS3ResourceUrl = (resourceKey = '') => `https://${publicS3Bucket}/${resourceKey}`;

// Server URL depending on environment
function getWebURL ( environment )
{
    switch ( environment )
    {
        case "local":
            return process.env.NEXT_PUBLIC_LOCAL_WEB_URL;
        case "dev":
            return process.env.NEXT_PUBLIC_DEV_WEB_URL;
        case "prod":
            return process.env.NEXT_PUBLIC_PROD_WEB_URL;
        default:
            return process.env.NEXT_PUBLIC_LOCAL_URL;
    }
}
export const webURL = getWebURL( environment );

const getDomain = ( environment ) =>
{
    const domain = webURL?.split( "://" )[ 1 ];

    switch ( environment )
    {
        case "local":
            return domain;
        case "dev":
            return `.${ domain }`;
        case "prod":
            return `.${ domain }`;
        default:
            return domain;
    }
};

export const webDomain = getDomain( environment );

export const formatValue = ( n ) =>
{
    return n?.toString().replace( /\B(?=(\d{3})+(?!\d))/g, "," );
};
export const getYoutubeUrl = ( url ) =>
{
    let urlRegex = /(https?:\/\/[^ ]*)/;
    let result = url.match( urlRegex )[ 1 ].replace( /['"]+/g, "" );
    let code = result.split( "/" ).slice( -1 )[ 0 ];
    let embeddedUrl = "https://www.youtube.com/embed/" + code;
    return embeddedUrl;
};
export const getId = ( url ) =>
{
    let urlRegex = /(https?:\/\/[^ ]*)/;
    let result = url.match( urlRegex )[ 1 ].replace( /['"]+/g, "" );
    return result;
};
// Constants
export const actionS3Objects = [ "getObject", "putObject", "deleteObject" ]; // S3 Bucket Actions

export const activeStatus = [ "Active", "Inactive" ];

export const roles = [ "MasterAdmin", "Admin", "User", "Agent" ]; // Roles

export const ListingType = [ "Buy", "Rent" ]; // Property Listing Types, add "Sell", "Lease", and "Holiday Homes" later

export const CompletionStatus = [ "Completed", "Off Plan" ]; // Property Completion Status

export const UnitStatusBuy = [ "Available", "Reserved", "Sold" ]; // Unit Status for Buy

export const UnitStatusRent = [
    "Available Soon",
    "Available Now",
    "Reserved",
    "Rented",
]; // Unit Status for Rent

export const BedRooms = [ "Studio", "1", "2", "3", "4", "5", "6", "7" ]; // No. of BedRooms

export const formatBedsString = (beds, long = false) => {
    if (beds === "NA") {
      return null;
    } else if (beds === "Studio") {
      return "Studio";
    } else {
      let bedText;
  
      if (beds === '7+') {
        bedText = long ? 'Bedrooms' : 'Beds';
      } else {
        bedText = long ? (beds > 1 ? 'Bedrooms' : 'Bedroom') : (beds > 1 ? 'Beds' : 'Bed');
      }
  
      return `${beds} ${bedText}`;
    }
  };
  
  export const formatBathsString = (baths, long = false) => {
    if (baths === "NA") {
      return null;
    } else {
      let bathText;
      
      if (baths === '7+') {
        bathText = long ? 'Bathrooms' : 'Baths';
      } else {
        bathText = long ? (baths > 1 ? 'Bathrooms' : 'Bathroom') : (baths > 1 ? 'Baths' : 'Bath');
      }
  
      return `${baths} ${bathText}`;
    }
  };
  
  
export const BathRooms = [ "1", "2", "3", "4", "5", "6", "7" ]; // No. of BathRooms

export const Finance = [ "Available", "Not available" ];

export const RentalPeriod = [ "Year", "Month" ];

export const Agent = [ "Assigned", "Not assigned" ];

export const Cheques = [ "1", "2", "4", "6", "12" ];

export const ListStatus = [ "Published" ]; // Property Listing Status

export const HomesEmail = "info@alba.homes";

export const HomesPhone = "+971582340982";

export const HomesAddress = "Alba Innovation Hub, Al Fattan Business Hub, JBR, Marina, Dubai, UAE";

export const HomesAddressLink =
    "https://www.google.com/maps/place/ALBA+HOMES/@25.1186846,55.2046183,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f6b3226e62805:0xa61c9e460d51f315!8m2!3d25.1186798!4d55.2071932!16s%2Fg%2F11trv7t2w0?entry=ttu";

// export const FormId = "1eSLcKvDomsfcg0xDpzNluM0-4WiIHaIdOL7lv68EvVg";

export const ActivityType = {
    Viewing: "Viewing",
    AskAQuestion: "Ask a Question",
    Call: "Call",
    WhatsApp: "WhatsApp",
    SMSMessage: "SMSMessage",
    Email: "Email",
    GetMatchedAssistance: "Get Matched Assistance",
};

export const Languages = {
    English: "English",
    Arabic: "Arabic",
    Russian: "Russian",
    French: "French",
    Mandarin: "Mandarin",
    Spanish: "Spanish",
    Farsi: "Farsi",
};

export const footerTypes = [ "Sea", "Desert", "Urban" ];
// ------------------------------------------------------------------------ //

export const getImage = async ( img ) =>
{
    try
    {

        const data = `https://${publicS3Bucket}/${img}`;
        return data;
    } catch ( err )
    {
        console.log( err );
    }
};

export const propertyTypes = [ "Villa", "Apartment", "Townhouse" ];

export const emirates = [
    "Dubai",
    "Abu Dhabi",
    "Sharjah",
    "Ajman",
    "Um Al Quwain",
    "Ras Al Khaimah",
    "Fujairah",
];

// Name of the Ids for Form
export const formName = {
    register: "Register",
    signIn: "Sign In",
    troubleSignUp: "Trouble Sign Up",
    newsletter: "Newsletter",
    contactUs: "Contact Us",
    AskAQuestion: "Ask A Question",
    viewing: "Schedule a Viewing",
    newListing: "New Listing",
    registerYourInterest: "Register your interest",
    downloadTheBrochure: "Download the brochure",
    getMatched: "Get Matched",
};

// Name of the Ids for Buttons
export const buttonName = {
    signIn: "Sign In",
    signUp: "Sign Up",
    register: "Register",
    submit: "Submit",
    subscribe: "Subscribe",
    newListing: "New Listing",
    deals: "Deals",
    forRent: "For Rent",
    forSale: "For Sale",
    getMatched: "Get Matched",
    learnMore: "Learn More",
    letsGetStarted: "Let's Get Started",
    viewing: "Schedule a Viewing",
    registerYourInterest: "Register your interest",
    downloadTheBrochure: "Download the brochure",
};

// REST COUNTRIES API ENDPOINT
export const restCountriesEndPoint = process.env.NEXT_PUBLIC_REST_COUNTRIES_API;

export const getRestCountries = async () =>
{
    try
    {
        const urlPattern =
            /(https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

        const res = restCountriesEndPoint?.match( urlPattern );

        if ( !res )
        {
            throw new Error( "invalid url" );
        }

        const { data } = await axios.get( restCountriesEndPoint );

        const countries = data
            ?.filter( ( country ) => country?.idd?.root )
            ?.map( ( country ) =>
            {
                return {
                    name: country?.name?.common ?? country?.name?.official,
                    code: `${ country?.idd?.root }${ ( country?.idd?.suffixes &&
                        country?.idd?.suffixes?.length ) === 1
                        ? country?.idd?.suffixes[ 0 ]
                        : ""
                        }`,
                    short: country?.cioc ?? country?.cca3 ?? country?.cca2,
                    image: country?.flags?.png ?? country?.flags?.svg,
                    cca2: country?.cca2,
                };
            } )
            ?.sort( ( a, b ) => ( a?.name < b?.name ? -1 : 1 ) );

        return countries;
    } catch ( err )
    {
        console.log( err );
        return [];
    }
};

export const validatePhoneNumbers = ( { code, phone, cca2 } ) =>
{
    if ( !phone || !code || !cca2 || phone.length < 2 )
    {
        return false;
    }

    if ( phone?.includes( code ) || isNaN( Number( `${ code }${ phone }` ) ) )
    {
        return false;
    }

    const phoneUtil = phoneLib.PhoneNumberUtil.getInstance();

    const parsedContact = phoneUtil?.parse( `${ code }${ phone }`, cca2 );

    if ( !parsedContact )
    {
        return false;
    }

    const isValid = phoneUtil.isValidNumberForRegion( parsedContact, cca2 );

    return isValid;
};

export function randomIntFromInterval ( min, max )
{
    // min and max included
    return Math.floor( Math.random() * ( max - min + 1 ) + min );
}

export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = ( value = "" ) =>
{
    const isValidEmail = value?.match( emailRegex );

    if ( isValidEmail )
    {
        return true;
    }

    return false;
};

export const specialCharRegex = /[^a-zA-Z\s]/; // Regex to match special characters
export const scriptRegex = /<[^>]*>|(<?php)|(\?>)|(<script)|(<\/script>)/i; // Regex to match script or js code

//form name field validation
export const validateName = (name) => {
    
    if (specialCharRegex.test(name)) {
        return "Name should not contain special characters";
    }
    if (scriptRegex.test(name)) {
        return "Name should not contain code snippets or script tags";
    }
    if (name.length > 30) {
        return "Name should not exceed 30 characters";
    }
    if (name.trim().length < 2) {
        return "Name too short";
    }
    if (!name.trim()) {
        return "Name is required";
    }
    return true;
};

//password field validation
export const validatePassword = (password) => {
    if (password.length < 6) {
        return "Password must be at least 6 characters.";
    }

    if (password.length > 15) {
        return "Name should not exceed 15 characters";
    }

    if (scriptRegex.test(password)) {
        return "Password should not contain code snippets or script tags";
    }
    return true;
};

//form text field validation
export const validateTextField = (text) => {   
    if (specialCharRegex.test(text)) {
        return "Should not contain special characters";
    }
    if (text.length > 64) {
        return "Name should not exceed 64 characters";
    }
    if (scriptRegex.test(text)) {
        return "Should not contain code snippets or script tags";
    }
    return true;
};

export function allPropertiesFilterParse(filter){
    let filterQuery = {}
                for (var key in filter) {
                    if (Array.isArray(filter[key])) {
                        filterQuery[key] = filter[key].filter(e => e && e?.value !== 'All')?.map(e => e?.value ?? e);
                    } else if (typeof filter[key] === "object") {
                        if (filter[key]?.name === 'size') {
                            filterQuery["minSize"] = filter[key]?.min ?? 0;
                            filterQuery["maxSize"] = filter[key]?.max ?? 100000;
                            continue;
                        }
                        if (filter[key]?.name === 'price') {
                            filterQuery["minPrice"] = filter[key]?.min ?? 0;
                            filterQuery["maxPrice"] = filter[key]?.max ?? 1000000000;
                            continue;
                        }

                        filterQuery[key] = filter[key]?.value === 'All' ? undefined : filter[key]?.value;
                    } else {
                        filterQuery[key] = filter[key];
                    }
                }
                return filterQuery
}

export function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

export const objectMap = ( obj, fn ) =>
    Object.fromEntries(
        Object.entries( obj ).map(
            ( [ k, v ], i ) => [ k, fn( v, k, i ) ]
        )
    );
   Object.filter = (obj, predicate) =>
        Object.fromEntries(Object.entries(obj).filter(predicate));


   export const uploadToS3 = async (
    file,
    fileName,
    contentType = "image/*",
  ) => {
    try {
       
      const uploadData = JSON.stringify({
        action: `${actionS3Objects[1]}`,
        fileName,
      });
  
      const url =  `${serverAPI}/upload`;
      const resp = await axios.post(url, uploadData,{headers:{'Content-Type':'application/json'}});
      const s3url = resp.data;
      
      if (s3url) {
        const myHeaders = new Headers({
          "Content-Type": contentType,
        });
  
        await axios.put(s3url, file, { myHeaders });
  
        return s3url;
      }
    } catch (err) {
      console.log(err);
  
  
      return null;
    }
  };

export const defaultCountry = {
    name: "United Arab Emirates",
    code: "+971",
    short: "UAE",
    image: "https://flagcdn.com/w320/ae.png",
    cca2: "AE",
};

export const offplanActions = {
    indexLeadForm: "new developments index form",
    contactUs: "Contact us",
    downloadBrochure: "Download brochure",
    newDevelopmentLeadForm: "new development lead form",
};

export const leadSources = {
    AskAQuestion: "Ask a Question",
    GetMatchedAssistance: "Get Matched Assistance",
    AdminCreated: "Admin Created",
    AgentCreated: "Agent Created",
    Register: "Register",
    NewListing: "New Listing",
    Viewing: "Viewing",
    Newsletter: "Newsletter",
    Imported: "Imported",
    Facebook: "Facebook",
    FaceBookCampaign: "FaceBookCampaign",
    Instagram: "Instagram",
    TikTok: "TikTok",
    Twitter: "Twitter",
    Whatsapp: "Whatsapp",
    Email: "Email",
    LinkedIn: "LinkedIn",
    Youtube: "Youtube",
    Pinterest: "Pinterest",
    Meta: "Meta",
    GoogleAds: "Google Ads",
    External: "External",
    NewDevelopment: "Off-Plan",
};

export const CategoryWeights = {
    Residential: 2,
    Commercial: 1,
    Offplan: 0
}